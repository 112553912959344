import './App.css';

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <span>
              Welcome to naumtsev.me
            </span>
            {LinksRow()}
            <div style={{maxWidth: '600px', maxHeight: '400px', overflow: 'hidden'}}>
                {Content()}
            </div>
        </header>
    </div>
  );
}

const LinksRow = () => {
    return (

    <div style={{display: 'flex', alignItems: 'center', alignContent: 'center'}}>
        Links:
        <a href="https://t.me/naumtsev"><img src="telegram-icon.svg" width={75} height={75} alt="telegram" /></a>
        <a href="https://github.com/naumtsev"><img src="github-icon.svg" width={75} height={75} alt="github" /> </a>
    </div>
  );
};


const Content = () => {
    const contents = [
        <img src="coders/content5.webp" alt="1"></img>,
        <img src="coders/content2.gif" alt="2"></img>,
        <img src="coders/content3.gif" alt="3"></img>,
        <img src="coders/content4.gif" alt="4"></img>,
        <img src="coders/content5.webp" alt="5"></img>
    ]
    const randomIndex = Math.floor(Math.random() * contents.length);
    return contents[randomIndex];

}

export default App;
